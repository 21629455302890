<template>
  <div>
    <Header />
    <div class="body-statistic">
      <section class="main-container pb-5" style="padding-top: 150px">
        <div class="container top-container">
          <div class="content">
            <div class="container" v-if="bannerTop">
              <!-- Banner -->

              <div
                v-for="(banner, key) in bannerTop"
                :key="key"
                class="mt-2 mb-2"
              >
                <a :href="banner.target_url" target="_blank"
                  ><img style="width: 100%" :src="banner.path"
                /></a>
              </div>
            </div>
            <div class="container">
              <div class="title-bar text-center">
                <div class="title margin-right-auto" style="font-size: 30px">
                  {{ $t("statistics.statistics") }}
                </div>
              </div>
              <div class="container grid grid-2">
                <div class="container">
                  <div
                    class="card-matches card-bg-blue"
                    style="font-size: 18px"
                  >
                    <div class="card-matches-title"></div>
                    <div
                      class="card-matches-content"
                      style="max-height: 640; max-width: 640"
                    >
                      <div class="col text-center">
                        <h5>{{ $t("statistics.win-rate-chart") }}</h5>
                      </div>
                      <div class="col">
                        <canvas id="myChart" width="550" height="550"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="container">
                    <div
                      class="card-matches card-bg-blue"
                      style="font-size: 18px"
                    >
                      <div class="card-matches-title"></div>
                      <div class="col mt-3">
                        {{ $t("nav.win") }} : ({{
                          matchSetting && matchSetting.total_win
                            ? matchSetting.total_win
                            : "-"
                        }}) {{ $t("nav.rate") }} :
                        {{
                          (matchSetting && matchSetting.total_win
                            ? matchSetting.total_win_rate
                            : 0) + "%"
                        }}
                        <div class="progress">
                          <div
                            class="progress-bar bg-danger"
                            role="progressbar"
                            :style="
                              'width: ' +
                              (matchSetting && matchSetting.total_win
                                ? matchSetting.total_win_rate
                                : 0) +
                              '%'
                            "
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col mt-3">
                        {{ $t("nav.lose") }} : ({{
                          matchSetting && matchSetting.total_lose
                            ? matchSetting.total_lose
                            : "-"
                        }}) {{ $t("nav.rate") }} :
                        {{
                          (matchSetting && matchSetting.total_win
                            ? matchSetting.total_lose_rate
                            : 0) + "%"
                        }}
                        <div class="progress">
                          <div
                            class="progress-bar bg-info"
                            role="progressbar"
                            :style="
                              'width: ' +
                              (matchSetting && matchSetting.total_win
                                ? matchSetting.total_lose_rate
                                : 0) +
                              '%'
                            "
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col mt-3">
                        {{ $t("nav.draw") }} : ({{
                          matchSetting && matchSetting.total_draw
                            ? matchSetting.total_draw
                            : "-"
                        }}) {{ $t("nav.rate") }} :
                        {{
                          (matchSetting && matchSetting.total_win
                            ? matchSetting.total_draw_rate
                            : 0) + "%"
                        }}
                        <div class="progress">
                          <div
                            class="progress-bar bg-success"
                            role="progressbar"
                            :style="
                              'width: ' +
                              (matchSetting && matchSetting.total_win
                                ? matchSetting.total_draw_rate
                                : 0) +
                              '%'
                            "
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="col mt-3">
                        <span> {{ $t("statistics.total-match") }} :</span>
                        <span class="hightlight-text">
                          {{
                            matchSetting && matchSetting.total_match
                              ? matchSetting.total_match
                              : "-"
                          }}</span
                        >
                        <span> {{ $t("statistics.total-win-rate") }}:</span>
                        <span class="hightlight-text">
                          {{
                            matchSetting && matchSetting.total_win_rate
                              ? matchSetting.total_win_rate
                              : "-"
                          }}
                          %</span
                        >
                      </div>
                    </div>
                    <div
                      class="card-matches card-bg-blue"
                      style="font-size: 18px"
                    >
                      <a target="_blank" :href="whatsappLink">
                        <span style="display: inline-block; color: white">{{
                          $t("home.join-whats")
                        }}</span
                        >&nbsp; &nbsp;
                        <img
                          src="../assets/img/whatsapp_green.png"
                          alt=""
                          style="
                            width: 40px;
                            height: 40px;
                            display: inline-block;
                          "
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Statistics",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      matchSetting: "",
      bannerHost: "https://72963.com",
      bannerSettings: [
        {
          title: "pc top",
          category: 1,
          status: 1,
          target_url: "https://978bet36.com/",
          cat_title: "电脑-顶部",
          path: "https://72963.com/uploads/images/20201214/5696d802b494e7f762dac27e22f08513.gif",
          width: 970,
          height: 150,
        },
        {
          title: "72963-pc",
          category: 1,
          status: 1,
          target_url: "https://77577.com",
          cat_title: "电脑-顶部",
          path: "https://72963.com/uploads/images/20201214/banner-77577-desktop.png",
          width: 970,
          height: 150,
        },
      ],
    };
  },
  props: {
    msg: String,
  },
  computed: {
    bannerTop() {
      if (this.bannerSettings != null) {
        return this.bannerSettings.filter(function (item) {
          return item.category == 1;
          //return item;
        });   
      } //

      return "";
    },
    whatsappLink() {
      var text = "https://wa.me/";
      var number_locale = this.$i18n.locale == "vi" ? "_vi" : "";
      var number =
        this.matchSetting &&
        this.matchSetting["whatsapp_number" + number_locale]
          ? this.matchSetting["whatsapp_number" + number_locale]
          : "";
      return text + number;
    },
  },
  created() {
    axios
      .get(`${this.bannerHost}/api/banner`)
      .then((response) => {
        const axiosBannerList = response.data.data;
        this.bannerSettings = axiosBannerList;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
  mounted() {
    //Api match detail

    axios
      .get(`/api/setting-detail`)
      .then((response) => {
        this.matchSetting = response.data.data;
        this.showChart();
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
  methods: {
    showChart() {
      this.$nextTick(function () {
        var Chart = require("chart.js");
        var ctx = document.getElementById("myChart");
        var total_win_rate = this.matchSetting
          ? this.matchSetting.total_win_rate
          : 0;
        var total_lose_rate = this.matchSetting
          ? this.matchSetting.total_lose_rate
          : 0;
        var total_draw_rate = this.matchSetting
          ? this.matchSetting.total_draw_rate
          : 0;
        // For a pie chart
        new Chart(ctx, {
          type: "pie",
          data: {
            labels: [
              this.$t("nav.win") + "(" + total_win_rate + "%)",
              this.$t("nav.draw") + "(" + total_draw_rate + "%)",
              this.$t("nav.lose") + "(" + total_lose_rate + "%)",
            ],
            datasets: [
              {
                label: "Win,Lose and Draw",
                backgroundColor: ["#dc3545", "#28a745", "#17a2b8"],
                data: [total_win_rate, total_lose_rate, total_draw_rate],
              },
            ],
          },
          options: {
            legend: {
              labels: {
                // This more specific font property overrides the global property
                fontColor: "white",
                fontSize: 20,
              },
            },
            title: {
              display: true,
              text: "",
            },
          },
        });
      });
    },
  },
};
</script>

<style scope>
</style>
