var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('div',{staticClass:"body-statistic"},[_c('section',{staticClass:"main-container pb-5",staticStyle:{"padding-top":"150px"}},[_c('div',{staticClass:"container top-container"},[_c('div',{staticClass:"content"},[(_vm.bannerTop)?_c('div',{staticClass:"container"},_vm._l((_vm.bannerTop),function(banner,key){return _c('div',{key:key,staticClass:"mt-2 mb-2"},[_c('a',{attrs:{"href":banner.target_url,"target":"_blank"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":banner.path}})])])}),0):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"title-bar text-center"},[_c('div',{staticClass:"title margin-right-auto",staticStyle:{"font-size":"30px"}},[_vm._v(" "+_vm._s(_vm.$t("statistics.statistics"))+" ")])]),_c('div',{staticClass:"container grid grid-2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card-matches card-bg-blue",staticStyle:{"font-size":"18px"}},[_c('div',{staticClass:"card-matches-title"}),_c('div',{staticClass:"card-matches-content",staticStyle:{"max-height":"640","max-width":"640"}},[_c('div',{staticClass:"col text-center"},[_c('h5',[_vm._v(_vm._s(_vm.$t("statistics.win-rate-chart")))])]),_vm._m(0)])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"card-matches card-bg-blue",staticStyle:{"font-size":"18px"}},[_c('div',{staticClass:"card-matches-title"}),_c('div',{staticClass:"col mt-3"},[_vm._v(" "+_vm._s(_vm.$t("nav.win"))+" : ("+_vm._s(_vm.matchSetting && _vm.matchSetting.total_win ? _vm.matchSetting.total_win : "-")+") "+_vm._s(_vm.$t("nav.rate"))+" : "+_vm._s((_vm.matchSetting && _vm.matchSetting.total_win ? _vm.matchSetting.total_win_rate : 0) + "%")+" "),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar bg-danger",style:('width: ' +
                            (_vm.matchSetting && _vm.matchSetting.total_win
                              ? _vm.matchSetting.total_win_rate
                              : 0) +
                            '%'),attrs:{"role":"progressbar","aria-valuenow":"100","aria-valuemin":"0","aria-valuemax":"100"}})])]),_c('div',{staticClass:"col mt-3"},[_vm._v(" "+_vm._s(_vm.$t("nav.lose"))+" : ("+_vm._s(_vm.matchSetting && _vm.matchSetting.total_lose ? _vm.matchSetting.total_lose : "-")+") "+_vm._s(_vm.$t("nav.rate"))+" : "+_vm._s((_vm.matchSetting && _vm.matchSetting.total_win ? _vm.matchSetting.total_lose_rate : 0) + "%")+" "),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar bg-info",style:('width: ' +
                            (_vm.matchSetting && _vm.matchSetting.total_win
                              ? _vm.matchSetting.total_lose_rate
                              : 0) +
                            '%'),attrs:{"role":"progressbar","aria-valuenow":"50","aria-valuemin":"0","aria-valuemax":"100"}})])]),_c('div',{staticClass:"col mt-3"},[_vm._v(" "+_vm._s(_vm.$t("nav.draw"))+" : ("+_vm._s(_vm.matchSetting && _vm.matchSetting.total_draw ? _vm.matchSetting.total_draw : "-")+") "+_vm._s(_vm.$t("nav.rate"))+" : "+_vm._s((_vm.matchSetting && _vm.matchSetting.total_win ? _vm.matchSetting.total_draw_rate : 0) + "%")+" "),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar bg-success",style:('width: ' +
                            (_vm.matchSetting && _vm.matchSetting.total_win
                              ? _vm.matchSetting.total_draw_rate
                              : 0) +
                            '%'),attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}})])]),_c('div',{staticClass:"col mt-3"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("statistics.total-match"))+" :")]),_c('span',{staticClass:"hightlight-text"},[_vm._v(" "+_vm._s(_vm.matchSetting && _vm.matchSetting.total_match ? _vm.matchSetting.total_match : "-"))]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("statistics.total-win-rate"))+":")]),_c('span',{staticClass:"hightlight-text"},[_vm._v(" "+_vm._s(_vm.matchSetting && _vm.matchSetting.total_win_rate ? _vm.matchSetting.total_win_rate : "-")+" %")])])]),_c('div',{staticClass:"card-matches card-bg-blue",staticStyle:{"font-size":"18px"}},[_c('a',{attrs:{"target":"_blank","href":_vm.whatsappLink}},[_c('span',{staticStyle:{"display":"inline-block","color":"white"}},[_vm._v(_vm._s(_vm.$t("home.join-whats")))]),_vm._v(" "),_c('img',{staticStyle:{"width":"40px","height":"40px","display":"inline-block"},attrs:{"src":require("../assets/img/whatsapp_green.png"),"alt":""}})])])])])])])])])])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col"},[_c('canvas',{attrs:{"id":"myChart","width":"550","height":"550"}})])}]

export { render, staticRenderFns }